<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">遗产要素现状记录</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :model="formdata.dataDic"
                :inline="true"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
                @submit.native.prevent
            >
                <el-form-item
                    label="遗产要素"
                    prop="YCYSBM"
                    :style="{ width: '80%' }"
                >
                    <el-select
                        v-model="formdata.dataDic.YCYSBM"
                        :disabled="!isEdit"
                        placeholder="请选择遗产要素"
                        filterable
                    >
                        <el-option
                            v-for="(item, index) in ycysList"
                            :key="index"
                            :label="item.MC"
                            :value="item.BM"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="现状评估"
                    prop="XZPG"
                    :style="{ width: '80%' }"
                >
                    <el-select
                        v-model="formdata.dataDic.XZPG"
                        :disabled="!isEdit"
                        placeholder="请选择现状评估"
                    >
                        <el-option
                            v-for="{ CODE, LABEL } in enumObj.XZPG"
                            :key="CODE"
                            :label="LABEL"
                            :value="CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="保存现状"
                    prop="BCXZ"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.BCXZ"
                        :disabled="!isEdit"
                        placeholder="请填写保存现状"
                    >
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
import { mapActions } from "vuex";

export default {
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                dataDic: {
                    glycbtid: this.heritageId,
                    YCYSBM: "",
                    XZPG: "",
                    BCXZ: "",
                },
                fileids: "",
                itemCode: "603",
                metaJson: [],
            },
            rules: {
                YCYSBM: [
                    {
                        required: true,
                        message: "请选择遗产要素",
                        trigger: "change",
                    },
                ],
                XZPG: [
                    {
                        required: true,
                        message: "请选择现状评估",
                        trigger: "change",
                    },
                ],
                BCXZ: [
                    {
                        required: true,
                        message: "请填写保存现状",
                        trigger: ["blur", "change"],
                    },
                ],
            },
            ycysList: [],
        };
    },
    mounted() {
        this.getYcysData();
    },
    methods: {
        ...mapActions(["GetYcysList"]),
        async getYcysData() {
            const res = await this.GetYcysList();
            if (res && res.ResultValue) {
                this.ycysList = res.ResultValue;
            }
        },
    },
};
</script>

<style></style>
